import React, { useRef, useState } from "react";
import "../styles/register.css"; // Create Register.css for styling
import RegisterHeader from "../components/Headers/RegisterHeader";
import { KJUR } from "jsrsasign";

const Register = () => {
  const [formCategory, setFormCategory] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [formProgress, setFormProgress] = useState(0);
  const [formPercentage, setFormPercentage] = useState(0);
  const [formSuccess, setFormSuccess] = useState(false);
  const progressRef = useRef();
  const [formData, setFormData] = useState({
    fullName: "",
    age: "",
    email: "",
    contact: "",
    schoolName: "",
    grade: "",
    mun: "",
    strengths: "",
    routine: "",
    parentalTime: "",
    confidenceLevel: "",
    confidenceFactors: "",
    hopeToAchieve: "",
  });

  const {
    fullName,
    age,
    email,
    contact,
    schoolName,
    grade,
    mun,
    strengths,
    routine,
    parentalTime,
    confidenceLevel,
    confidenceFactors,
    hopeToAchieve,
  } = formData;

  const SERVICE_ACCOUNT_EMAIL =
    "mj-website@mj-website-427713.iam.gserviceaccount.com";
  const PRIVATE_KEY = `-----BEGIN PRIVATE KEY-----
\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCwAhPxtpiTMrFg\n1p+pLOXqVLsSVtp/Ix2s6c6IotIsd/HDPulquduQqKT6TLuTC2z6rvniHBQeUhQ7\nH+jXLoiT1N6fwzpP39qQfkM6eoY9vGpewrROisUfwPD6/b8VU/bxMq8AWdDVOtle\nFDK/GCzVcgvxDOSYl9En/1sOAPkcyzovbeo25yVgnnngvP3Y8EgVW7G2atLyqU0f\nSUWNeVSZ1Y5IcVALt3dfHC926DkQOVkJfsQbVHmd72Oq20GzTLWE2zxi5Xv31qZJ\nmckALmEO+x3iDlNh9MqX3QzXiDdrauQId6fF+fisORi8E0q/nqKKq/8nCAl0sGuh\nKJmQsz5RAgMBAAECggEAMGr56pH1CfU1h3KzLDpOKzbQ2XwsEjJqTPFqn/rOf24l\ngzTwyCvFo1dJPxPRu+M2QQXWduWLgUXKC/O8vUkvSB+UeHWfum5VtU8Olm0zNZN5\n3YSJTlvtW01b3WflY2wcMADl1F5gzO+p8S/0P85aMwNzFoYZ8oI9Btg/x6vr24f0\nl8E84dPJ/xAh5aImlYGOBx4xxYSpspQD1NxzFGTY+L1+K+ZaEmJdpLTSmAL5aSTU\nWhJ3LoWvhGE38dKcPrYnO0MIaIokyop/MX+FQV7OhgXLg3k6QBepwik9t2ZCW4a1\nMsB1/vxB1splDxjz0Wt9/rOWtlCdxufbB9itHcqaYwKBgQDz9KfNxMZdjhGAz7Eo\np56/+JhiyDGV0xeTnppPV8eDJcsE4K4uZ2g+ENZBZqilVOJ6ZUokfy35sxeMINNU\nodT31Au2pbSc51sM6tG4x+7Zq77IseNBoz6i5E4BmH3yocR4bhFW+VjkuT5nbCrR\nyYNbyKl3O9svoo9MtclmrTZ6FwKBgQC4sqLi//Wv1YfiBEI4x8FySsCyd7wbi2ub\nUNctH1LuKfYs8ftvDGFXoqfVN14fL42+oSe7zcCAnLoeBq6+Xil3OcU9SKj2AF5+\nUA2nFh5Cp/5Oy2HVo9jMJq0SLkq3uTLhhoK7nh1HR8/ySk5e/Bgsynby31loiPTM\nFRZ33xUT1wKBgCuZwVfsYEueNZChDhenBZ9nzcxx3TLa3G7cvNWLabQN0CAaC+dt\nQds95R0hPOj3p9aaPcsSE+R51q6Lu1l35vk4I7PMH+CfFM3VNhBhY1Xx9RIngOdd\nRreUOEDewTvZH6Uqftzdib6vQPD5CxodeuWvkmImB4GW+yeJoepA8OiXAoGBAJ3A\nti98v7Q3haAeUxF8TFX6Uywb6Kb+JiK6oY8AuiyB2qn45PCselgHraIHcHTigQbZ\nTr6xtkIjmzQaMRaYBoK27Siy7t1u40avyXl1AtOb+TIDtMRs9bcpMZndwCO8IyFN\nakwleIxxYcNohBfM6jkXlRFMg2kt0SieuloYSEBxAoGAJW9ZzKMyVnWI7XY1fiFk\nteA1wa3Epntud/CRVohCs5xBnCOll4APFr/enQGAzo+JlvHdlXNoqFFQy2PI6vr+\ncBeqGXpnBC7v+SctOnSaZeSb02733Q3p0vbmPjxaF8K2/JWTOeZ0QkfuUCMYGiLt\naN9oqTrj6Eg3dQz7ruVO+5Y=\n
-----END PRIVATE KEY-----`;
  const SCOPES = ["https://www.googleapis.com/auth/spreadsheets"];
  const SPREADSHEET_ID = "1vaV_I5d8b0ksSbrxdYC9L2oTTWrHORB7xbMnXjJvrxU";
  const API_URL = "https://sheets.googleapis.com/v4/spreadsheets/";

  async function authenticate() {
    const now = Math.floor(Date.now() / 1000);
    const jwtHeader = { alg: "RS256", typ: "JWT" };
    const jwtClaimSet = {
      iss: SERVICE_ACCOUNT_EMAIL,
      scope: SCOPES.join(" "),
      aud: "https://oauth2.googleapis.com/token",
      exp: now + 3600,
      iat: now,
    };

    const jwt = KJUR.jws.JWS.sign("RS256", jwtHeader, jwtClaimSet, PRIVATE_KEY);

    const response = await fetch("https://oauth2.googleapis.com/token", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
        assertion: jwt,
      }),
    });

    const data = await response.json();
    return data.access_token;
  }

  const appendData = async (accessToken, values) => {
    const response = await fetch(
      `${API_URL}${SPREADSHEET_ID}/values/Sheet1!A1:append?valueInputOption=RAW`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ values }),
      }
    );
    return response.json();
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setFormSuccess(true);
    const accessToken = await authenticate();
    let values = [
      [
        fullName,
        email,
        age,
        contact,
        schoolName,
        grade,
        mun,
        strengths,
        routine,
        parentalTime,
        confidenceLevel,
        confidenceFactors,
        hopeToAchieve,
      ],
    ];
    const result = await appendData(accessToken, values);
    console.log("result", result, result.updates.updatedRows);
    if (result) {
      if (result.updates.updatedRows) {
        setFormSuccess(true);
      }
    }
  };

  const handleCategorySelection = (category) => {
    setFormCategory(category);
    setCurrentTab(0); // Start at the first tab of the form
  };

  const handleNextTab = () => {
    // Validate current tab fields before proceeding to the next
    if (validateCurrentTab()) {
      setCurrentTab(currentTab + 1);
      setFormProgress(
        formProgress + progressRef.current.getBoundingClientRect().width / 7
      );
      setFormPercentage(Math.round(((currentTab + 1) / 7) * 100));
    }
  };

  const handlePrevTab = () => {
    setCurrentTab(currentTab - 1);
    setFormProgress(
      formProgress - progressRef.current.getBoundingClientRect().width / 7
    );
    setFormPercentage(Math.round(((currentTab - 1) / 7) * 100));
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validation for current tab
  const validateCurrentTab = () => {
    const fieldsToValidate = {
      0: ["fullName", "age", "email", "contact", "schoolName", "grade", "mun"],
      1: ["strengths"],
      2: ["routine"],
      3: ["parentalTime"],
      4: ["confidenceLevel"],
      5: ["confidenceFactors"],
      6: ["hopeToAchieve"],
    };

    const currentFields = fieldsToValidate[currentTab];
    const isValid = currentFields.every(
      (field) => formData[field].trim() !== ""
    );

    if (!isValid) {
      alert("Please complete all fields.");
      return false;
    }
    return true;
  };

  return (
    <div className="w-full h-[100vh] bg_image">
      <RegisterHeader />
      <div className="register-container">
        <div className="flex flex-col items-center">
          {!formSuccess && (
            <div id="heading" className="w-full flex flex-col items-center">
              <p class="akiraFont headingStyle text-[#3b0f10]">Register</p>
              <div>
                <p class="headingDetail !text-[16px] !font-normal">
                  Please fill the form below to complete your registration. Feel
                  free to add as much detail as needed.
                </p>
              </div>
            </div>
          )}

          {!formCategory && (
            <div className="categoryForm">
              <h1 className="headingStyle akiraFont headingblueColor">
                Are you a...
              </h1>
              <div className="catButtonBox">
                <button
                  className="catButton"
                  onClick={() => handleCategorySelection("parent")}
                >
                  Parent
                </button>
                <button
                  className="catButton"
                  onClick={() => handleCategorySelection("student")}
                >
                  Student
                </button>
              </div>
            </div>
          )}

          {formCategory === "student" && !formSuccess && (
            <div id="formPaper" className="formPaper  ">
              <div className="progressBox flex items-center gap-3 ">
                <div ref={progressRef} className="progressBar">
                  <div
                    id="backgroundfb"
                    style={{ width: `${formProgress}px` }}

                    // style={{ width: `${(currentTab + 1) * 8}%` }}
                  ></div>
                </div>
                <p className="progressText">
                  {/* {(currentTab + 1) * 8}% */}
                  {formPercentage}%
                </p>
              </div>

              <form
                className={
                  formCategory === "student" ? "studentForm" : "parentForm"
                }
                onSubmit={submitForm}
              >
                {currentTab === 0 && (
                  <div className="regTab ">
                    <h1 className="headingStyle akiraFont headingblueColor">
                      Contact Details
                    </h1>
                    <p
                      className="montserratFont"
                      style={{ margin: "5px 0px 5px 0px" }}
                    >
                      Help us get to know you better
                    </p>
                    <div className="formInputStyling">
                      <input
                        type="text"
                        name="fullName"
                        placeholder="Name"
                        class="formInputStyle"
                        value={formData.fullName}
                        onChange={handleInputChange}
                      />
                      <input
                        type="number"
                        name="age"
                        placeholder="Age"
                        class="formInputStyle"
                        value={formData.age}
                        onChange={handleInputChange}
                      />
                      <input
                        type="email"
                        name="email"
                        class="formInputStyle"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="contact"
                        class="formInputStyle"
                        placeholder="Phone Number"
                        value={formData.contact}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="schoolName"
                        class="formInputStyle"
                        placeholder="Name of School"
                        value={formData.schoolName}
                        onChange={handleInputChange}
                      />
                      <select
                        name="grade"
                        className="formSelectStyle"
                        value={formData.grade}
                        onChange={handleInputChange}
                      >
                        <option value="0">Grade</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                      </select>
                    </div>
                    <select
                      id="mun"
                      name="mun"
                      value={formData.mun}
                      class="formSelectStyle my-4"
                      onChange={handleInputChange}
                    >
                      <option value="0">Select MUN to register</option>
                      <option value="Yale">Yale MUN</option>
                      <option value="Oxford">Oxford MUN</option>
                      <option value="Harvard">Harvard MUN</option>
                      <option value="UN headquarters ">UN headquarters</option>
                    </select>
                  </div>
                )}

                {currentTab === 1 && (
                  <div className="regTab">
                    <div>
                      <h1 class="headingStyle akiraFont headingblueColor">
                        About You
                      </h1>
                      <p
                        style={{ margin: "5px 0px 5px 0px" }}
                        class="montserratFont"
                      >
                        Help us get to know you better
                      </p>
                    </div>
                    <textarea
                      name="strengths"
                      placeholder="What are your strengths?"
                      rows="4"
                      form="studentForm"
                      value={formData.strengths}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                {currentTab === 2 && (
                  <div className="regTab">
                    <div>
                      <h1 class="headingStyle akiraFont headingblueColor">
                        About You
                      </h1>
                      <p
                        style={{ margin: "5px 0px 5px 0px" }}
                        class="montserratFont"
                      >
                        Help us get to know you better
                      </p>
                    </div>

                    <textarea
                      name="routine"
                      placeholder="Describe your typical routine during weekdays and weekends."
                      rows="4"
                      value={formData.routine}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {currentTab === 3 && (
                  <div className="regTab">
                    <div>
                      <h1 class="headingStyle akiraFont headingblueColor">
                        About You
                      </h1>
                      <p
                        style={{ margin: "5px 0px 5px 0px" }}
                        class="montserratFont"
                      >
                        Help us get to know you better
                      </p>
                    </div>
                    <textarea
                      name="parentalTime"
                      rows="4"
                      placeholder="How much time does each parent spend with you on a typical day?"
                      value={formData.parentalTime}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {currentTab === 4 && (
                  <div className="regTab">
                    <div>
                      <h1 class="headingStyle akiraFont headingblueColor">
                        About You
                      </h1>
                      <p
                        style={{ margin: "5px 0px 5px 0px" }}
                        class="montserratFont"
                      >
                        Help us get to know you better
                      </p>
                    </div>
                    <select
                      name="confidenceLevel"
                      className="formSelectStyle"
                      value={formData.confidenceLevel}
                      onChange={handleInputChange}
                    >
                      <option value="Not Selected">Confidence level</option>
                      <option value="Extremely Poor">Extremely Poor</option>
                      <option value="Poor">Poor</option>
                      <option value="Good Enough">Good Enough</option>
                      <option value="High">High</option>
                      <option value="Extremely High">Extremely High</option>
                    </select>
                  </div>
                )}
                {currentTab === 5 && (
                  <div className="regTab">
                    <div>
                      <h1 class="headingStyle akiraFont headingblueColor">
                        About You
                      </h1>
                      <p
                        style={{ margin: "5px 0px 5px 0px" }}
                        class="montserratFont"
                      >
                        Help us get to know you better
                      </p>
                    </div>
                    <textarea
                      name="confidenceFactors"
                      placeholder="State the factors that you believe have led to the level of confidence mentioned previously"
                      rows="4"
                      value={formData.confidenceFactors}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
                {currentTab === 6 && (
                  <div className="regTab">
                    <div>
                      <h1 class="headingStyle akiraFont headingblueColor">
                        About You
                      </h1>
                      <p
                        style={{ margin: "5px 0px 5px 0px" }}
                        class="montserratFont"
                      >
                        Help us get to know you better
                      </p>
                    </div>
                    <textarea
                      name="hopeToAchieve"
                      placeholder="My confidence level is..."
                      rows="4"
                      value={formData.hopeToAchieve}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                <div className="categoryButtonBox">
                  {currentTab > 0 && (
                    <button
                      type="button"
                      className="prevButton"
                      onClick={handlePrevTab}
                    >
                      Prev
                    </button>
                  )}
                  {currentTab < 6 && (
                    <button
                      type="button"
                      className="nextButton"
                      onClick={handleNextTab}
                    >
                      Next
                    </button>
                  )}
                  {currentTab === 6 && (
                    <button type="submit" className="submitButton">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}

          {formCategory === "parent" && !formSuccess && (
            <div id="formPaper" className="formPaper">
              <div className="progressBox flex items-center gap-3">
                <div ref={progressRef} className="progressBar">
                  <div
                    id="backgroundfb"
                    style={{ width: `${formProgress}px` }}

                    // style={{ width: `${(currentTab + 1) * 8}%` }}
                  ></div>
                </div>
                <p className="progressText">{formPercentage}%</p>
              </div>

              <form
                className={
                  formCategory === "student" ? "studentForm" : "parentForm"
                }
                onSubmit={submitForm}
              >
                {currentTab === 0 && (
                  <div className="regTab">
                    <h1 className="headingStyle akiraFont headingblueColor">
                      Contact Details
                    </h1>
                    <p
                      className="montserratFont"
                      style={{ margin: "5px 0px 5px 0px" }}
                    >
                      Help us get to know your child better
                    </p>
                    <div className="formInputStyling">
                      <input
                        id="fullNameParent"
                        className="formInputStyle"
                        type="text"
                        name="fullName"
                        placeholder="Name of Child"
                        value={formData.fullName}
                        onChange={handleInputChange}
                      />
                      <input
                        id="ageParent"
                        name="age"
                        className="formInputStyle"
                        type="number"
                        placeholder="Age of Child"
                        min="0"
                        value={formData.age}
                        onChange={handleInputChange}
                      />
                      <input
                        id="emailParent"
                        className="formInputStyle"
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      <input
                        id="contactParent"
                        className="formInputStyle"
                        type="text"
                        name="contact"
                        placeholder="Phone Number"
                        value={formData.contact}
                        onChange={handleInputChange}
                      />
                      <input
                        id="schoolParent"
                        className="formInputStyle"
                        type="text"
                        name="schoolName"
                        placeholder="Name of School"
                        value={formData.schoolName}
                        onChange={handleInputChange}
                      />
                      <select
                        name="grade"
                        className="formSelectStyle"
                        value={formData.grade}
                        onChange={handleInputChange}
                      >
                        <option value="0">Grade</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                      </select>
                    </div>
                    <select
                      id="mun"
                      name="mun"
                      value={formData.mun}
                      class="formSelectStyle my-4"
                      onChange={handleInputChange}
                    >
                      <option value="0">Select MUN to register</option>
                      <option value="Yale">Yale MUN</option>
                      <option value="Oxford">Oxford MUN</option>
                      <option value="Harvard">Harvard MUN</option>
                      <option value="UN headquarters ">UN headquarters</option>
                    </select>
                  </div>
                )}

                {currentTab === 1 && (
                  <div className="regTab">
                    <h1 className="headingStyle akiraFont headingblueColor">
                      About Your Child
                    </h1>
                    <p
                      style={{ margin: "5px 0px 5px 0px" }}
                      class="montserratFont"
                    >
                      Help us get to know your child better
                    </p>
                    <textarea
                      name="strengths"
                      placeholder="What are their strengths?"
                      rows="4"
                      value={formData.strengths}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {currentTab === 2 && (
                  <div className="regTab">
                    <div>
                      <h1 class="headingStyle akiraFont headingblueColor">
                        About The Student
                      </h1>
                      <p
                        style={{ margin: "5px 0px 5px 0px" }}
                        class="montserratFont"
                      >
                        Help us get to know your child better
                      </p>
                    </div>
                    <textarea
                      name="routine"
                      placeholder="Describe their typical routine"
                      rows="4"
                      value={formData.routine}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {currentTab === 3 && (
                  <div className="regTab">
                    <h1 class="headingStyle akiraFont headingblueColor">
                      About The Student
                    </h1>
                    <p
                      style={{ margin: "5px 0px 5px 0px" }}
                      class="montserratFont"
                    >
                      Help us get to know your child better
                    </p>
                    <textarea
                      name="parentalTime"
                      placeholder="How much time do you spend with your child?"
                      rows="4"
                      value={formData.parentalTime}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {/* {currentTab === 4 && (
                  <div className="regTab">
                    <h1 class="headingStyle akiraFont headingblueColor">
                      About The Student
                    </h1>
                    <p
                      style={{ margin: "5px 0px 5px 0px" }}
                      class="montserratFont"
                    >
                      Help us get to know your child better
                    </p>
                    <textarea
                      name="confidenceLevel"
                      placeholder="My child's confidence level is..."
                      rows="4"
                      value={formData.confidenceLevel}
                      onChange={handleInputChange}
                    />
                  </div>
                )} */}
                {currentTab === 4 && (
                  <div className="regTab">
                    <h1 class="headingStyle akiraFont headingblueColor">
                      About The Student
                    </h1>
                    <p
                      style={{ margin: "5px 0px 5px 0px" }}
                      class="montserratFont"
                    >
                      Help us get to know your child better
                    </p>
                    <select
                      name="confidenceLevel"
                      className="formSelectStyle"
                      value={formData.confidenceLevel}
                      onChange={handleInputChange}
                    >
                      <option value="Not Selected">Confidence level</option>
                      <option value="Extremely Poor">Extremely Poor</option>
                      <option value="Poor">Poor</option>
                      <option value="Good Enough">Good Enough</option>
                      <option value="High">High</option>
                      <option value="Extremely High">Extremely High</option>
                    </select>
                  </div>
                )}
                {currentTab === 5 && (
                  <div className="regTab">
                    <h1 class="headingStyle akiraFont headingblueColor">
                      About The Student
                    </h1>
                    <p
                      style={{ margin: "5px 0px 5px 0px" }}
                      class="montserratFont"
                    >
                      Help us get to know your child better
                    </p>
                    <textarea
                      name="confidenceFactors"
                      placeholder="State the factors that you believe have led to the level of confidence mentioned previously"
                      rows="4"
                      value={formData.confidenceFactors}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
                {currentTab === 6 && (
                  <div className="regTab">
                    <h1 class="headingStyle akiraFont headingblueColor">
                      About The Student
                    </h1>
                    <p
                      style={{ margin: "5px 0px 5px 0px" }}
                      class="montserratFont"
                    >
                      Help us get to know your child better
                    </p>
                    <textarea
                      name="hopeToAchieve"
                      placeholder="What do you hope for your child to achieve from attending the MUN sessions?"
                      rows="4"
                      value={formData.hopeToAchieve}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                <div className="categoryButtonBox">
                  {currentTab > 0 && (
                    <button
                      type="button"
                      className="prevButton"
                      onClick={handlePrevTab}
                    >
                      Prev
                    </button>
                  )}
                  {currentTab < 6 && (
                    <button
                      type="button"
                      className="nextButton"
                      onClick={handleNextTab}
                    >
                      Next
                    </button>
                  )}
                  {currentTab === 6 && (
                    <button type="submit" className="submitButton">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}

          {formSuccess && (
            <div id="formEnd" class="formEnd">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="text-center">
                  <h1 class="headingStyle akiraFont headingblueColor">
                    Thank you
                  </h1>
                  <p
                    style={{ margin: "5px 0px 5px 0px" }}
                    class="montserratFont"
                  >
                    for your submission. We will get back to you shortly
                  </p>
                </div>
                <div>
                  <p class="contactStyle">Contact +923002773182</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
